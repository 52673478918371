import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent
  } from "@ionic/react";
  import React, { useEffect } from "react";
  import "./Selector.css";
  
  const Selector: React.FC = () => {
  
    useEffect(() => {
    }, []);
          
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
          </IonToolbar>
        </IonHeader>
        <IonContent
          fullscreen
          className="ion-padding ion-text-center"
          class="tab5-content">
          

          <div className="selector_home">
            <div className="selector_group_title">
                <div className="selector_title">
                  PORTAIL DE VISUALISATION DES SAUVEGARDES
                </div>
            </div>

            <div className="selector_group_log">
              <div className="selector_name_log">
                <div className="selector_log_access_title">
                  LOG REQUETES SIMPLES
                </div>
                <div className="selector_log_error_title">
                  LOG REQUETES COMPLETES
                </div>
              </div>
              <div className="selector_show_log">
                <div className="selector_log_access">
                  <object data="assets/logs/test.log" />
                </div>
                <div className="selector_log_error">
                  <object data="assets/logs/test.log" />
                </div>
              </div>
            </div>

            <div className="selector_group_button">
              <div className="selector_buttons">
                <div className="selector_div_button">
                  <IonCard className="selector_button" routerLink="/dash">
                    <IonCardHeader>
                      <IonCardTitle className="card_title">
                        Sauvegarde Incrémentale
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card_content">
                      Permet de visualiser les différentes sauvegardes des sociétés ainsi que les données de chacune d'entre elles.
                      </IonCardContent>
                  </IonCard>
                </div>
                <div className="selector_div_button">
                  <IonCard className="selector_button" routerLink="/visu">
                    <IonCardHeader>
                      <IonCardTitle className="card_title">
                        VISU SIMPLE
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card_content">
                      non.
                      </IonCardContent>
                  </IonCard>
                </div>
                <div className="selector_div_button">
                  <IonCard className="selector_button" routerLink="/check">
                    <IonCardHeader>
                      <IonCardTitle className="card_title">
                        Visualisation simple des sauvegardes
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card_content">
                      Permet d'avoir un regard sur les sauvegardes ainsi que les récupérations de façon simplifié et clair.
                    </IonCardContent>
                  </IonCard>
                </div>
              </div>
            </div>
          </div>

        </IonContent>
      </IonPage>
    );
  };
  
  export default Selector;
  