import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonButton
  } from "@ionic/react";
  import React, { useState, useEffect } from "react";
  
  import "./Checking.css";
  import { handleApiRequest } from "../App";
  import { useHistory } from "react-router-dom";
  import { download, saveSharp } from "ionicons/icons";
  import { saveFile } from "jsrsasign";
  
  import { saveAs } from 'file-saver';

  import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
  import { File } from '@awesome-cordova-plugins/file';
  
  
  const Checking: React.FC = () => {
    let history = useHistory();

    const [saveRecupCount, setSaveRecupCount] = useState(0)
    const [saveRecup, setSaveRecup] = useState(new Array())
    const [societeRecupCount, setSocieteRecupCount] = useState(0)
    const [societeRecup, setSocieteRecup] = useState(new Array())

    function getSaveRecup(){
      // console.log("\n\nRecupération\n\n");
      handleApiRequest(
        "GET",
        "whosaved/",
        {},
        (res: any) => {
            // console.log("DONE RECUPERATION recup");
                        
            setSaveRecupCount( Object.keys(res.data).length );
            
            let tempListSociete = new Array();
          
            for (let i=0; i<Object.keys(res.data).length; i++){
              let temp = new Array();
              
              temp.push(res.data[i]['count']);
              temp.push(res.data[i]['finess']);
              temp.push(res.data[i]['nom']);
              temp.push(res.data[i]['patron_prenom']);
              temp.push(res.data[i]['patron_nom']);


              tempListSociete.push(temp);
            }
          
          setSaveRecup(tempListSociete);
          
        },
        (error: any) => {
          console.log("ECHEC RECUPERATION recup : " + error);
        }
      )
    }

    function getLastSaveDataDateByFiness(finess: String){
      let sd = "";
      handleApiRequest(
        "GET",
        "societes/"+finess+"/savedata/",
        {},
        (res: any) => {
            sd = res.data[res.data.length-1].date_created;
        },
        (error: any) => {
          console.log("ECHEC RECUPERATION societes : " + error);
        }
      );
      return sd;
    }

    function getSaveSociete(){
      handleApiRequest(
        "GET",
        "whosavedincre/",
        {},
        (res: any) => {
            // console.log("DONE RECUPERATION societes");
            console.log(res);

            var date = new Date();
            // 2 semaines
            var twoweeks = new Date();
            twoweeks.setDate(date.getDate()-14);

            // 1 semaine
            var oneweek = new Date();
            oneweek.setDate(date.getDate()-7);

            // 3 jours
            var threedays = new Date();
            threedays.setDate(date.getDate()-3);
            

            setSocieteRecupCount ( Object.keys(res.data).length );

            let tempListSociete = new Array();
            for (let i=0; i<Object.keys(res.data).length; i++){
              let temp = new Array();
              temp.push(res.data[i]['nom']);
              temp.push(res.data[i]['finess']);
              temp.push(res.data[i]['patron_prenom']);
              temp.push(res.data[i]['patron_nom']);
              temp.push(res.data[i]['date_created']);
              
              if(res.data[i]['date_created']){
                var test2W = compareDate( twoweeks, new Date(res.data[i]['date_created']) );
                var test1W = compareDate( oneweek, new Date(res.data[i]['date_created']) );
                var test3D = compareDate( threedays, new Date(res.data[i]['date_created']) );

                if(test2W == 1){
                  temp.push("colorError");
                } else if(test1W == 1){
                  temp.push("colorWarning");
                } else if(test3D == 1){
                  temp.push("colorPrevent");
                } else {
                  temp.push("colorGood");
                }
              }
              else temp.push("error");

              

              // var d1 = new Date();
              // var test = compareDate(d1, twoweeks);
              // console.log(test);


              
              tempListSociete.push(temp);
            };

            setSocieteRecup(tempListSociete);
          
        },
        (error: any) => {
          console.log("ECHEC RECUPERATION societes : " + error);
        }
      );
    }

    /** 
     * Compares two Date objects and returns e number value that represents 
     * the result:
     * 0 if the two dates are equal.
     * 1 if the first date is greater than second.
     * -1 if the first date is less than second.
     * @param date1 First date object to compare.
     * @param date2 Second date object to compare.
     */
    function compareDate(date1: Date, date2: Date)
    {
      // With Date object we can compare dates them using the >, <, <= or >=.
      // The ==, !=, ===, and !== operators require to use date.getTime(),
      // so we need to create a new instance of Date with 'new Date()'
      let d1 = new Date(date1); let d2 = new Date(date2);
    
      // Check if the dates are equal
      let same = d1.getTime() === d2.getTime();
      if (same) return 0;
    
      // Check if the first is greater than second
      if (d1 > d2) return 1;
     
      // Check if the first is less than second
      if (d1 < d2) return -1;
    }

    function downloadRecup(finess: any) {
      handleApiRequest(
        "dl",
        "societes/" + finess + "/recuperation/",
        {},
        (res: any) => {
          // // download(res);
          // console.log(res);
          // var binaryData = [];
          // binaryData.push(res.data);

          // // fileTra
          
          // saveAs(window.URL.createObjectURL(new Blob( res.data, {type: "application/fmp12"})), "TAXIHANDGO.fmp12");
        },
        (error: any) => {
          console.log("ECHEC RECUPERATION societes : " + error);
        }
      );
    }

    useEffect(() => {
      getSaveRecup();
      getSaveSociete();
    }, []);
  
          
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButton onClick={() => { history.goBack(); }}>Retour</IonButton>
            <IonTitle>Check data</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent
          fullscreen
          className="ion-padding ion-text-center"
          class="tab5-content">

            <div className="appCheck">

              <div className="part1-count">
                <div className="part1-count-recup">
                  <IonCard>
                    <IonCardHeader>
                    <IonCardSubtitle>Nombre de sociétés</IonCardSubtitle>
                    <IonCardTitle>{saveRecupCount}</IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                </div>
                <div>
                  <IonButton onClick={() => { getSaveRecup(); getSaveSociete(); }}>RELOAD</IonButton>
                </div>
                <div className="part1-count-societe">
                  <IonCard>
                    <IonCardHeader>
                    <IonCardSubtitle>Nombre de sociétés</IonCardSubtitle>
                    <IonCardTitle>{societeRecupCount}</IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                </div>
              </div>

              <div className="part2-display">
                <div className="part2-display-recup">
                  {saveRecup.map((s) => (
                    
                      <div className="card-position">
                        <div className="card-middle">
                          <IonCard>
                            <IonCardHeader>
                              <IonCardSubtitle>{s[2] == null ? " --- " : s[2]}</IonCardSubtitle>
                              <IonCardTitle>{s[1]}</IonCardTitle>
                              ({s[3] == null ? " --- " : s[3]} {s[4] == null ? " --- " : s[4]})
                            </IonCardHeader>
                          </IonCard>
                        </div>
                        <div className="card-right">
                          <IonCard>
                            <IonCardHeader>
                              <IonCardSubtitle>Nombre de</IonCardSubtitle>
                              <IonCardSubtitle>sauvegarde</IonCardSubtitle>
                              <IonCardTitle>{s[0]}</IonCardTitle>
                            </IonCardHeader>
                          </IonCard>
                        </div>
                        <div className="card-right">
                          <IonCard>
                            <IonCardHeader>
                              <IonCardSubtitle>Téléchargement</IonCardSubtitle>
                              <IonCardTitle>
                                <IonButton onClick={() => { downloadRecup(s[1]) }}>▼</IonButton>
                              </IonCardTitle>
                            </IonCardHeader>
                          </IonCard>
                        </div>
                      </div>
                    
                  ))}
                </div>
                <div className="part2-display-societe">
                  {societeRecup.map((s) => (
                      <><div className={s[5]}>
                      <IonCard className="card_status">
                        <IonCardHeader>
                          <IonCardSubtitle>{s[0] == null ? " --- " : s[0]}</IonCardSubtitle>
                          <IonCardTitle>{s[1]}</IonCardTitle>
                          ({s[2] == "" ? " --- " : s[2]} {s[3] == "" ? " --- " : s[3]})
                        </IonCardHeader>
                      </IonCard>
                    </div>
                    
                    <div>
                      
                      </div></>
                  ))}
                </div>
              </div>

            </div>

        </IonContent>
        
      </IonPage>
    );
  };
  
  export default Checking;
  