import { Redirect, Route } from "react-router-dom";
import {
  getPlatforms,
  IonApp,
  IonRouterOutlet
} from "@ionic/react";
import { IonReactRouter } from '@ionic/react-router';




import DownloadFile from './DownloadFile';



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Data from "./pages/Data";
import Selector from "./pages/Selector";
import Checking from "./pages/Checking";

import { File } from '@ionic-native/file';

import { b64utoutf8, KJUR } from "jsrsasign";
import * as jsonConfig from "./app_scor_config.json"
import axios from "axios";

//URL du serveur de save, ex : test.save.taxihandgo, localhost:8000, save.taxihandgo...
export const urlServeur = jsonConfig.serveurURL;

//URL du serveur d'admin, ex : test.admin.taxihandgo, admin.taxihandgo...
export const urlAdmin = jsonConfig.adminURL;

//Certificat du serveur d'admin, ex : test.admin.taxihandgo, admin.taxihandgo...
export const pemString = jsonConfig.pemString;

//On regarde la connexion internet au démarrage
export let hasInternet = navigator.onLine;

//Permet de générer un refresh automatique des pages en cas de retour arrière
window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
  if ( historyTraversal ) {
    // Handle page restore.
    window.location.reload();
  }
});

export function setRefresh(userRefresh: string)
{
  localStorage.setItem('refresh', JSON.stringify(userRefresh));
}


export function setToken(userToken: string)  //Fonction qui stock le token dans le stockage de session
{
  sessionStorage.setItem('jwt', JSON.stringify(userToken));
  let payloadObj:any = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(userToken.split(".")[1]));

  //Lorsqu'on recupere le token, on en extrait le finess
  //On cree le dossier de l'utilisateur au passage si ce dernier se connecte pour la premiere fois
  if(payloadObj)
  {
    sessionStorage.setItem('jwtExp', payloadObj.exp);
    sessionStorage.setItem('finess', payloadObj.societe_finess);

    //Si on est sur téléphone
    // console.log(''+getPlatforms())
    if((''+getPlatforms()).includes("mobile"))
    {
      // console.log("IOS")
      File.checkDir(File.dataDirectory, payloadObj.societe_finess).catch(err => {
        // console.log('Aucun dossier trouvé'+JSON.stringify(err));
        File.createDir(File.dataDirectory, payloadObj.societe_finess, false).then(response => {
          // console.log('Dossier crée'+response);
        }).catch(err => {
          // console.log('Dossier non crée'+JSON.stringify(err));
        }); 
      });
    }
  }
}

export function actualiseExpToken()
{
  //Fonction qui permet de rallonger le temps d'expiration du token en cas d'action par l'utilisateur
  //On récupère l'objet associé au jwt
  let strExpDate = sessionStorage.getItem('jwtExp');
  if(strExpDate)
  {
    let expDate:number = parseInt(strExpDate);
    let dateNow = Date.now()/1000;
    expDate = dateNow+3600;
    sessionStorage.setItem('jwtExp', expDate.toString());
  }

}
export function getToken()  
{
  //Fonction qui retourne le token stocké s'il est valide uniquement
  //Si le token stocke est périmé on le supprime
  const tokenString = sessionStorage.getItem('jwt');
  let userToken: any;
  if(tokenString != null)
  {
    userToken = JSON.parse(tokenString);

    let strExpDate = sessionStorage.getItem('jwtExp')
    
    if(strExpDate)
    {
      let expDate:number = parseInt(strExpDate);
      let dateNow = Date.now()/1000; // On recupere le timestamp actuel
      
      // console.log(expDate - dateNow)
      //Si la date actuelle est plus vieille que la date de peremption le token est perime
      if(expDate - dateNow <= 0)
      {
        //On utilise donc le refresh token pour en récupérer un nouveau 
        const api = axios.create({
          baseURL: urlAdmin
        });

        let refresh = ''+localStorage.getItem('refresh')
        refresh = refresh.replace('"', "");
        refresh = refresh.replace('"', "");

        api
        //On fait une requete post avec l'username et le pwd dans le body de la requete
        .post("/login/refresh", {
          "refresh_token": refresh
        })
        .then((res) => {
          setToken(res.data.token)
          
        })
        .catch((error) => {
          //On retourne aucun token et on supprime l'ancien
          userToken = undefined;
          sessionStorage.removeItem('jwt');
          localStorage.removeItem('refresh')
          sessionStorage.removeItem('jwtExp');
          sessionStorage.removeItem('finess');
          window.location.reload()
          
        });
        
      }
    }
  }
  return userToken
}

// downloadEmployeeData = () => {
//       fetch('https://save.taxihandgo.com/societes/762565224/recuperation/')
//         .then(response => {
//           response.blob().then(blob => {
//             let url = window.URL.createObjectURL(blob);
//             let a = document.createElement('a');
//             a.href = url;
//             a.download = 'employees.json';
//             a.click();
//           });
//           //window.location.href = response.url;
//       });
//     }

//Fonction qui s'occupe de génerer une requête vers l'api
//Prend en parametre : 
//le type, la suite de l'url, le body, la fonctionnalité (pour les console.log())
export function handleApiRequest(type:string, urlRequest: string, bodyRequest:any, thenFunction:any, catchFunction:any)
{
  const api = axios.create({
    baseURL: urlServeur,
  });

  let authStr:any = 'Bearer ' + sessionStorage.getItem('jwt');

  //Le replaceAll ne fonctionne pas sur tous les ipads
  //authStr = authStr.replaceAll('"', "");
  authStr = authStr.replace('"', "");
  authStr = authStr.replace('"', "");
  // console.log(authStr);
  if(type==='GET') {
    api
    .get(urlRequest,
    {'headers': { 'Authorization': authStr }})
    .then((res) => 
    {
      thenFunction(res);
    })
    .catch((error) => 
    { 
      catchFunction(error);       
    });
  }else
  {
    if(type==='POST')
    {
      api
      .post(urlRequest,
        bodyRequest,
      {'headers': { 'Authorization': authStr }})
      .then((res) => 
      {
        thenFunction(res);
      })
      .catch((error) => 
      { 
        catchFunction(error);       
      });
    }else
    {
      if(type==='PUT')
      {
        api
        .put(urlRequest,
          bodyRequest,
        {'headers': { 'Authorization': authStr }})
        .then((res) => 
        {
          thenFunction(res);
        })
        .catch((error) => 
        { 
          catchFunction(error);       
        });
      }else
      {
        if(type==='dl')
        {
          fetch(urlRequest, {
          headers: {'Authorization': authStr}
          })
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = 'TAXIHANDGO.json';
              a.click();
            });
            //window.location.href = response.url;
          })
          .catch((error) => 
          { 
            catchFunction(error);       
          });
        // console.log('Mauvais type de requête : essayez avec "GET"/"POST"/"PUT" ')
        }
      }
    }
  }
}

const App: React.FC = () => {
  let loggedIn = getToken() != undefined;
  
  // console.log(hasInternet?"Internet disponible":"Internet indisponible");

  //Si on perd la connexion internet
  window.addEventListener('offline', () => {
    // console.log("Perte de la connexion internet");
    hasInternet = false;
  });

  //Si on retrouve la connexion internet
  window.addEventListener('online', () => {
    // console.log("Récupération de la connexion internet");
    hasInternet = true;

      //A chaque fois qu'on retrouve internet on sauvegarde les fichiers à sauvegarder
    if(loggedIn)
    {
      // saveUnsavedFile();
      window.location.reload();
    }
  });

  //Au lancement de l'application on sauvegarde les fichiers à sauvegarder
  if(loggedIn)
  {
    // saveUnsavedFile();
  }


  //if(hasInternet)
  //{
    return(
      <IonApp>    
        <IonReactRouter>
            <IonRouterOutlet id="main">
              
  
              <Route exact path="/">
                {loggedIn ? <Selector/> : <Login/>}
              </Route>
    
              <Route exact path="/dash">
                {loggedIn ? <Dashboard/> : <Redirect to="/"/>}
              </Route>

              <Route exact path="/data">
                {loggedIn ? <Data/> : <Redirect to="/data"/>}
              </Route>

              <Route exact path="/check">
                {loggedIn ? <Checking/> : <Redirect to="/"/>}
              </Route>
  
              <Route exact path="/linked">
                {/* {loggedIn ? <Linked/> : <Redirect to="/"/>} */}
              </Route>
              
              <Route exact path="/test">
                ReactDOM.render(<DownloadFile />, document.getElementById('root'));
              </Route>
  
            </IonRouterOutlet>
        </IonReactRouter>
  
  
      </IonApp>
    )
  
};

export default App;

