import React from 'react';

class DownloadFile extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	downloadEmployeeData = () => {
		fetch('https://save.taxihandgo.com/societes/292514510/recuperation/', {
			headers: {'Authorization': 'Bearer ' + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJpYXQiOjE2NDQ1NzMyODYsImV4cCI6MTY0NDU3Njg4NiwianRpIjoiNjIwNjMyNjZlODY2ZSIsInJvbGVzIjpbIlJPTEVfQURNSU4iLCJST0xFX1VTRVIiXSwidXNlcm5hbWUiOiJhbnRvaW5lLmxldmFzc2V1ckB0YXhpaGFuZGdvLmNvbSIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJzb2NpZXRlX2ZpbmVzcyI6IjAwMDAwMDAwMCJ9.bE6iKcSU31VOsF2KwkyZ7pXjKIiMBERE58RtmJDfTpXTn2gpr0BVn6vPVdEuUyPzEOligTmqpGG3zEcfLzq7pU-m90G0mc1tFag4gj9HRQgqBtz_fksLr-aFYoEcmfbwjKxblCW7m1z1CIfnp5IaPiZbuxCvw1RuqtzNAknAr75m0454u9EhSiLpg_USOE-fVdNK4zIMwqV6Bj5oL4P-VbIeO1CWoe5lRpzJiraiLA-bWI8gap_Tl0s089_tWGpq85jIyJHikkZhCjiWGaXk9tXvrobqPUyjaWSlsZSG0BcNi7s6y32kJ4dzvPOruVPk1N7baKGMsUVqyHgV8XqwSA"}
		})
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'TAXIHANDGO.json';
					a.click();
				});
				//window.location.href = response.url;
		});
	}
	
	render() {
		return (
			<div id="container">
				<h1>Download File using React App</h1>
				<h3>Download Employee Data using Button</h3>
				<button onClick={this.downloadEmployeeData}>Download</button>
				<p/>
				<h3>Download Employee Data using Link</h3>
				<a href="#" onClick={this.downloadEmployeeData}>Download</a>
			</div>
		)
	}

}

export default DownloadFile;