import {
  IonCheckbox,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { personCircle } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonAlert,
} from "@ionic/react";
import { KEYUTIL, KJUR, RSAKey } from 'jsrsasign'
import { handleApiRequest, pemString, setRefresh, setToken, urlAdmin, urlServeur } from "../App";

//Cette fonction permet de verifier la validité de l'email avec un regex
function validateEmail(email: string) 
{
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function verifySignature(token: string) 
{
  /*
  //TO DO : Résoudre le problème CORS pour recuperer le certificat directement via l'url
  //Correspond au certificat admin-taxihandgo.com.pem
  const sslCertificate = require('get-ssl-certificate-next')

  //let pemString = "";

  
  sslCertificate.get('admin.taxihandgo.com').then(function (certificate:any) {
    console.log(certificate)
    console.log(certificate.pemEncoded)
    //pemString = certificate.pemEncoded;
    // -----BEGIN CERTIFICATE-----
    // ...
    // -----END CERTIFICATE-----
  }).catch((error: any) =>{
    console.log("error : " + error);
  })
  ;
  */

  //@TODO 
  //Changer la méthode de récupération du certificat pour ne pas avoir de problème tous les mois

  //On recupere la clé  
  //let publicKey = KEYUTIL.getKey(pemString); 

  //On vérifie si le token verifie la clé publique avec l'algo RS512
  let isValid = KJUR.jws.JWS.verifyJWT(token, pemString, {alg: ['RS512']});

  return isValid;
}


const Login: React.FC = () => {
  const history = useHistory();
  
  //username et password saisie par l'utilisateur
  const emailStocked = ''+localStorage.getItem('email')==='null'?'':''+localStorage.getItem('email');

  
  const [username, setEmail] = useState<string>(emailStocked);
  const [password, setPassword] = useState<string>();

  const [iserror, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [showAlert, setShowAlert] = useState(false);

  const [remember, setRemember] = useState(emailStocked!==null);

  //On vérifie que l'utilisateur n'a pas de refreshToken valide au chargement de la page
  useEffect(() => {
    //Gérer la connexion auto si refresh token dispo de valide
    const refreshStocked = ''+localStorage.getItem('refresh')==='null'?'':''+localStorage.getItem('refresh');
  
    if(refreshStocked !== '')
    {
      console.log("Refresh disponible")
      //On utilise donc le refresh token pour en récupérer un nouveau 
      const api = axios.create({
        baseURL: urlAdmin
      });

      let refresh = ''+localStorage.getItem('refresh')
      refresh = refresh.replace('"', "");
      refresh = refresh.replace('"', "");

      api
      //On fait une requete post avec l'username et le pwd dans le body de la requete
      .post("/login/refresh", {
        "refresh_token": refresh
      })
      .then((res) => {
        let hasRightSignature = verifySignature(res.data.token);
          
        if(hasRightSignature)//Si le token a la bonne signature
        {
          //On le stock dans la session storage
          setToken(res.data.token);
          setRefresh(res.data.refresh_token)
          apiInfosRequest(res.data.token);
        }
      })
    }
  }, []);

  function apiInfosRequest(token:string)
  {
    console.log("TOKENNN " + token)
    const societeFiness = ""+sessionStorage.getItem('finess');
    let userName = "";
    let userFirstName = "";
  
    /* ----------------------------- */
    /*       REQUETE VERS L'API      */
    /* ----------------------------- */
        

      // handleApiRequest("GET","/societes/"+societeFiness+"/", {},(res:any)=>{
      //   console.log(res.data);
      //   userName = res.data.patron_nom;
      //   userFirstName = res.data.patron_prenom;

      //   sessionStorage.setItem("userName",userName);
      //   sessionStorage.setItem("userFirstName",userFirstName);
        window.location.reload();
      // },(error:any)=>{
      //   console.log("Aucune information dans la base de données");
      //   //On invite l'utilisateur à réessayer
      //   setMessage("Aucune information dans notre base de données, merci de contacter le support");
      //   setIserror(true);
      // })
  }

  const handleLogin = () => 
  {
    

    if (!username) { //S'il n'y a pas d'username saisi
      setMessage("Saisissez une adresse mail");
      setIserror(true);

      console.log("Saisissez une adresse mail")
      return;
    }
    if (validateEmail(username) === false) { //Si l'username saisi n'est pas valide
      setMessage("Votre adresse mail est invalide");
      setIserror(true);

      console.log("Votre adresse mail est invalide")
      return;
    }

    if (!password) { //Si le mot de passe n'est pas saisi
      setMessage("Saisissez un mot de passe");
      setIserror(true);

      console.log("Saisissez un mot de passe")
      return;
    }

    //Objet qui stock les ids rentrés par l'utilisateur
    const loginData = {
      username: username,
      password: password,
    };

    //Si l'utilisateur l'autorise on stock l'adresse mail en localStorage pour l'enregistrer
    if(remember)
    {
      localStorage.setItem('email', username);
    }
    else
    {
      localStorage.setItem('email', '');
    }

    /* ----------------------------- */
    /*       REQUETE VERS L'API      */
    /* ----------------------------- */

    const api = axios.create({
      baseURL: urlAdmin
    });
    
    api
    //On fait une requete post avec l'username et le pwd dans le body de la requete
      .post("/login", {
        "username": loginData.username,
        "password": loginData.password,
      })
      .then((res) => {
        //Si la requête abouti à un résultat (code 200)
        //On verifie la signature du token RS512
        //let hasRightSignature = verifySignature(res.data.token);
	let hasRightSignature = true;        

        if(hasRightSignature)//Si le token a la bonne signature
        {
          //On le stock dans la session storage
          setToken(res.data.token);
          setRefresh(res.data.refresh_token)
          // apiInfosRequest(res.data.token);
          window.location.reload();
        }
        else //Si le token n'a pas la bonne signature
        {
          //On invite l'utilisateur à réessayer
          setMessage("Erreur dans la vérification du jeton d'authentification ! Veuillez réessayer");
          setIserror(true);
          console.log("Erreur dans la vérification du jeton d'authentification ! Veuillez réessayer");

        }
      })
      .catch((error) => {
        //Si la requête n'abouti à rien
        console.log(error)
        if(error.response)
        {
          if(error.response.status === 401) //Si on a un code 401 on invite à réessayer avec un bon duo email / mdp
          {
            setMessage("Echec lors de l'authentification, votre mot de passe ou votre adresse mail est incorrect");
            setIserror(true);
            console.log("Echec lors de l'authentification, votre mot de passe ou votre adresse mail est incorrect");
          }
          else{ //Sinon on affiche simplement le code de l'erreur obtenu
            setMessage(
              "Une erreur est survenue lors de votre authentification -> code d'erreur : " +
                error.response.status 
            );
            setIserror(true);
          }
        }
        else
        {
          setMessage(error);
          setIserror(true);
        }
      });
  };

  return ( //Affichage
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Connexion</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
          
            

            <IonCol>
              <IonAlert
                isOpen={iserror}
                onDidDismiss={() => setIserror(false)}
                cssClass="my-custom-class"
                header={"Erreur"}
                message={message}
                buttons={["OK"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>

            <IonCol>
              <IonIcon
                style={{ fontSize: "70px", color: "#0040ff" }}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          <IonRow>

            <IonCol>
              <IonItem>
                <IonLabel position="floating">Adresse mail</IonLabel>
                <IonInput
                  placeholder="Adresse mail"
                  type="email"
                  value={username}
                  onIonChange={(e) => setEmail(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Mot de passe</IonLabel>
                <IonInput
                  placeholder="Mot de passe"
                  type="password"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                  <IonLabel>Se souvenir de mon adresse mail</IonLabel>
                  <IonCheckbox slot="start" value='checkRemember' checked={remember} onIonChange={e => setRemember(e.detail.checked)}/>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <IonButton expand="block" onClick={handleLogin}>
                Se connecter
              </IonButton>
              
            </IonCol>
          </IonRow>
         
        </IonGrid>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Attention'}
          message={'Aucune donnée n\'est disponible dans nos sauvegardes, merci de contacter le support'}
          buttons={[
            {
              text: 'OK'
            }
          ]}/>

      </IonContent>
    </IonPage>
  );
};

export default Login;
