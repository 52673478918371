import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import "./Dashboard.css";
import { handleApiRequest } from "../App";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Dashboard: React.FC = () => {
  let history = useHistory();

  // requests
  const [listSociete, setListSociete] = useState(new Array());
  const [listSaveData, setListSaveData] = useState(new Array());
  const [listSaveDataEntity, setListSaveDataEntity] = useState(new Array());

  // Value
  const [nameSelected, setNameSelected] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [dateSelected, setDateSelected] = useState();
  const [sortSelected, setSortSelected] = useState("name");

  const [finessSelected, setFinessSelected] = useState("");

  const colorState = ["orange", "green", "red"];
  const [cssChoose, setCssChoose] = useState('.landing-page { background-color: ' + colorState[0] +'; }');


  function getSaveData(finess: any) {
    handleApiRequest(
      "GET",
      "societes/" + finess + "/savedata/",
      {},
      (res: any) => {
        console.log("request getSaveData done");


        res.data.map((obj: any) => {
          obj.date_created = getDate(new Date(obj.date_created));
        });

        res.data[0].type_save == 0
          ? setTypeSelected("Initiale")
          : setTypeSelected("Incrémentale");
        setDateSelected(res.data[0].date_created);

        
        let tempListUid = new Array();
        res.data.map((obj: any) => {
          obj.uid = obj.uid.replace(/-/g, "");
          
          obj.state == true
          ? (obj.color = "colorError")
          : (obj.color = "colorOk");

          tempListUid.push(obj);
        });

        setListSaveData(tempListUid);
        console.log(tempListUid);
        getSaveDataEntity(listSaveData[0].uid);
      },
      (error: any) => {
        console.log("request error : " + error);
      }
    );
  }

  function getDate(date: Date) {
    // console.log(date);
    var minutes =
      date.getMinutes().toString().length == 1
        ? "0" + date.getMinutes()
        : date.getMinutes();
    var hours =
      date.getHours().toString().length == 1
        ? "0" + date.getHours()
        : date.getHours();
    var seconds =
      date.getSeconds().toString().length == 1
        ? "0" + date.getSeconds()
        : date.getSeconds();
    var months = [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "decembre",
    ];
    var days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];

    return (
      days[date.getDay()] +
      " " +
      date.getDate() +
      " " +
      months[date.getMonth()] +
      " " +
      date.getFullYear() +
      " " +
      hours +
      "h " +
      minutes +
      "m " +
      seconds +
      "s"
    );
  }

  function getSaveDataEntity(uid: any) {
    handleApiRequest(
      "GET",
      "savedata/" + uid + "/savedataentity",
      {},
      (res: any) => {
        console.log("request getSaveDataEntity done");
        console.log(res);

        let tempListUid = new Array();
        res.data.map((obj: any) => {
          obj.uid = obj.uid.replace(/-/g, "");
          obj.data_on_app !== obj.data_on_server
            ? (obj.color = "colorError")
            : (obj.color = "colorOk");
          // console.log(obj);

          tempListUid.push(obj);
        });

        tempListUid.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        setListSaveDataEntity(tempListUid);

        setCssChoose(`
        .landing-page {
            background-color: blue;
        }
        `)

      },
      (error: any) => {
        console.log("request error : " + error);
      }
    );
  }

  function sort(sortBy: String) {
    // console.log(sortBy);
    switch (sortBy) {
      case "name":
        setListSaveDataEntity(
          listSaveDataEntity.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        );
        break;
      case "time_save":
        setListSaveDataEntity(
          listSaveDataEntity.sort(function (a, b) {
            if (a.time_save < b.time_save) {
              return -1;
            }
            if (a.time_save > b.time_save) {
              return 1;
            }
            return 0;
          })
        );
        break;
      case "data_on_app":
        setListSaveDataEntity(
          listSaveDataEntity.sort(function (a, b) {
            if (a.data_on_app < b.data_on_app) {
              return -1;
            }
            if (a.data_on_app > b.data_on_app) {
              return 1;
            }
            return 0;
          })
        );
        break;
      case "data_on_server":
        setListSaveDataEntity(
          listSaveDataEntity.sort(function (a, b) {
            if (a.data_on_server < b.data_on_server) {
              return -1;
            }
            if (a.data_on_server > b.data_on_server) {
              return 1;
            }
            return 0;
          })
        );
        break;
    }
  }

  useEffect(() => {
    handleApiRequest(
      "GET",
      "societes/",
      {},
      (res: any) => {
        console.log("DONE RECUPERATION SOCIETES");
        // console.log(res);

        let tempListSociete = new Array();
        // console.log("Display : ");
        res.data.map((obj: any) => {
          // console.log("patron_nom : " + obj.patron_nom)
          // console.log("patron_prenom : " + obj.patron_prenom)
          let temp = new Array();
          temp.push(obj.nom);
          temp.push(obj.finess);
          temp.push(obj.patron_prenom);
          temp.push(obj.patron_nom);
          obj.saveState == true
          ? (obj.color = "colorError")
          : (obj.color = "colorOk");
          temp.push(obj.color);
          temp.push(obj.date_created);
          
          // console.log(obj);

          tempListSociete.push(temp);
        });

        setListSociete(tempListSociete);
      },
      (error: any) => {
        console.log("ECHEC RECUPERATION SOCIETES" + error);
      }
    );
  }, []);

        
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton onClick={() => { history.goBack(); }}>Retour</IonButton>
          <IonTitle>Visualisation des données de sauvegarde.</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className="ion-padding ion-text-center"
        class="tab5-content"
      >
        <div className="app">
          <div className="part1">
            <div className="card">
            
              {listSociete.map((s) => (
                <div className={s[4]}>
                  <IonCard
                    onClick={() => {
                      setNameSelected(s[0]);
                      getSaveData(s[1]);
                      setFinessSelected(s[1]);

                      localStorage.setItem("finess_visu", s[1]);
                    }}
                    >
                    <IonCardHeader>
                      <IonCardSubtitle>{s[0]}</IonCardSubtitle>
                      <IonCardTitle>{s[1]}</IonCardTitle>
                      ({s[2]} {s[3]})
                    </IonCardHeader>
                  </IonCard>
                </div>
              ))}
            </div>
          </div>

          <div className="part2">
            <div className="part2-select">
              <div className="part2-select-view">
                <div className="part2-select-view-name">{nameSelected}</div>
                <div className="part2-select-view-type">{typeSelected}</div>
                <div>
                  <Link to="/data">
                    <IonButton>
                      Voir les données {finessSelected} →
                    </IonButton>
                  </Link>
                </div>
              </div>
              <div className="part2-select-date">
                <h4>Sauvegarde du : </h4>
                {dateSelected}
              </div>
            </div>

            <div className="part2-display">
              <div className="part2-display-save">
                <div className="card">
                  {listSaveData.map((s, index) => (
                    
                    <div className={s.color}>
                        <IonCard 
                          onClick={() => {
                            s.type_save == 0
                              ? setTypeSelected("Initiale")
                              : setTypeSelected("Incrémentale");
                            setDateSelected(s.date_created);
                            getSaveDataEntity(s.uid);
                          }}
                        >
                          <IonCardHeader>
                            <IonCardSubtitle>{s.uid}</IonCardSubtitle>
                            <IonCardTitle>Sauvegarde n°{index + 1}</IonCardTitle>
                          </IonCardHeader>
                        </IonCard>
                        </div>

                  ))}
                </div>
              </div>
              <div className="part2-display-data">
                {/* <div className="part2-display-data-part1">
                  part2-display-data-part1
                </div> */}
                <table>
                  <thead>
                    <tr>
                      <td>
                        <IonCard
                          onClick={() => {
                            // console.log(sortSelected + " to ");
                            setSortSelected("name");
                            sort("name");
                          }}
                        >
                          <IonCardTitle>ENTITE</IonCardTitle>
                        </IonCard>
                      </td>
                      <td>
                        <IonCard
                          onClick={() => {
                            // console.log(sortSelected + " to ");
                            setSortSelected("time_save");
                            sort("time_save");
                          }}
                        >
                          <IonCardTitle>TEMPS DE SAUVEGARDE</IonCardTitle>
                        </IonCard>
                      </td>
                      <td>
                        <IonCard
                          onClick={() => {
                            // console.log(sortSelected + " to ");
                            setSortSelected("data_on_app");
                            sort("data_on_app");
                          }}
                        >
                          <IonCardTitle>DONNEES SUR THG</IonCardTitle>
                        </IonCard>
                      </td>
                      <td>
                        <IonCard
                          onClick={() => {
                            // console.log(sortSelected + " to ");
                            setSortSelected("data_on_server");
                            sort("data_on_server");
                          }}
                        >
                          <IonCardTitle>DONNEES SUR LE SERVEUR</IonCardTitle>
                        </IonCard>
                      </td>
                    </tr>
                  </thead>

                  <tbody className="part2-display-data-scroll">
                    {listSaveDataEntity.map((s, index) => (
                      <tr>
                        <td className="tdNameEntity">{s.name}</td>
                        <td className="tdData">{s.time_save}</td>
                        <td className={s.color}>{s.data_on_app}</td>
                        <td className={s.color}>{s.data_on_server}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
