/**
 * Affiche la page de visualisation concrète des données.
 */

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLabel,
  IonTabButton,
  IonButton,
  IonItem,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { handleApiRequest } from "../App";

import "./Data.css";

const Data: React.FC = () => {
  let history = useHistory();

  const [finess_visu, setFinessVisu] = useState("");
  const [described, setDescribed] = useState(new Array());
  const [data, setData] = useState(new Array(new Array()));
  
  const [load, setLoad] = useState(0);
  
  var entities = [
    "",
    "clientsconventiones/",
    "clientsprofessionels/",
    "conducteurs/",
    "facturesconventiones/",
    "facturesnonconventionnes/",
    "lignesfacturesnonconventionnes/",
    "lotsnoemie/",
    "piecesjointes/",
    "transports/",
    "vehicules/",
  ];

  var display_entities = [
    "Sociétés",
    "Clients Conventionnées",
    "Clients Profesionnels",
    "Conducteurs",
    "Factures Conventionnées",
    "Factures Non Conventionnées",
    "Lignes Factures Non Conventionnées",
    "Lots Noémies",
    "Pièces Jointes",
    "Transports",
    "Vehicules",
  ];

  useEffect(() => {
    setFinessVisu("" + localStorage.getItem("finess_visu"));
    // console.log("-----------     " + load + "    ----------- ");
  });

  function describeEntity(entity: any) {
    console.log("\n\n\n-----------START LOAD----------- ");
    console.log("-----------     " + load + "    ----------- ");
    
    handleApiRequest(
      "GET",
      "societes/" + finess_visu + "/" + entity,
      {},
      (res: any) => {
        let tempDescribed = new Array();
        let tempListData = new Array();

        console.log("request " + entity + " done");
        // console.log(res.data)
        if(entity != ""){
          Object.keys(res.data[0]).map((obj: any) => {
            tempDescribed.push(obj);
          });
          res.data.map((obj: any) => {
            console.log(obj);
            let tempData = new Array();
  
            for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const element = obj[key];
                if (key == "societe" && typeof(element) == "object") {
                  var tempElement = "";
                  for (const i of element) tempElement += i + "\n";
                  tempData.push(tempElement);
                }
                else tempData.push(element);
              }
            }
            tempListData.push(tempData);

          });
        }
        else{
          Object.keys(res.data).map((obj: any) => {
            tempDescribed.push(obj);
          });
          let tempData = new Array();
            for (const key in res.data) {
                const element = res.data[key];
                tempData.push(element);
            }
            tempListData.push(tempData);
          }

        setDescribed(tempDescribed);
        setData(tempListData);
        console.log("-----------END LOAD----------- ");
        // console.log("-----------   " + load + "    ----------- ");
        // setLoad(0);
      },
      (error: any) => {
        console.log("request error : " + error);
        setDescribed(new Array());
        setData(new Array(new Array()));
        console.log("-----------END LOAD----------- ");
        // console.log("-----------   " + load + "    ----------- ");
        // setLoad(0);
      }
      );
  }

  function getDisplayName(e: any){
    let view = "";
    
    view += display_entities[ (entities.indexOf(e)) ];

    return view;
  }

  function cleanVar(){
    setDescribed(new Array());
    setData(new Array());
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton onClick={() => {cleanVar(); history.goBack(); }}>Retour</IonButton>
          Data of : {finess_visu}

          {/* <div className="circle"></div> */}

        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className="ion-padding ion-text-center"
        class="tab5-content"
      >
        {/* Data visu */}
        <div className="app">
          <div className="part1">
            <div className="card">
              {entities.map((e) => (
                <IonCard
                  onClick={() => {
                    console.log("-----------   " + load + "    ----------- ");

                    setLoad(1);
                    cleanVar();
                    describeEntity(e);
                    console.log("-----------   " + load + "    ----------- ");
                  }}
                >
                  <IonCardHeader>
                    <IonCardTitle>{getDisplayName(e)}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              ))}
            </div>
          </div>
          <div className="part2">
            <table >
              <thead>
                {described.map((d) => (
                  <td>{d}</td>
                ))}
              </thead>

              <tbody>
                {
                  data.map((d :any) => ( <tr> {d.map((d2: any) => ( <td className="sizeCase"><div className="part2-td">{d2}</div></td> )) } </tr> ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Data;
